@import "../../../../SCSS/mixins/utilities";

.contact_form {
  max-width: 15rem;
  margin-top: 2rem;

  @include respond-above(md) {
    max-width: 18rem;
    margin-top: 0;
  }
}
.signup_header {
  font-size: 1.6rem;
  @include respond-above(md) {
    font-size: 1.5rem;
  }
  @include respond-above(lg) {
    font-size: 1.8rem;
  }
  @include respond-above(xl) {
    font-size: 2rem;
  }
}
.thank_you {
  font-family: "Dancing Script";
  font-size: 1.6rem;
  transform: rotate(-8deg);
  max-width: 15rem;

  @include respond-above(sm) {
    font-size: 1.8rem;
    max-width: 20rem;
  }
  @include respond-above(md) {
    font-size: 1.6rem;
  }
  @include respond-above(lg) {
    font-size: 1.8rem;
  }
  @include respond-above(xl) {
    font-size: 2rem;
    max-width: 25rem;
  }
  @include respond-above(xxl) {
    font-size: 2.4rem;
    max-width: 25rem;
  }
}
