.candy_cane {
  width: 80%;
  max-width: 300px;
  height: 0.25rem;
  background: repeating-linear-gradient(
    -45deg,
    $sa-red,
    $sa-red 10px,
    $sa-cream 10px,
    $sa-cream 20px
  );
  &.cc_shadow {
    @media (min-width: 576px) {
      filter: drop-shadow(0rem 0.6rem 0.8rem $sa-black);
    }
    filter: drop-shadow(0rem 0.3rem 0.4rem $sa-black);
  }
  &.full_width {
    width: 100%;
    max-width: 100%;
  }
  &.tall {
    height: 0.3rem;
    @include respond-above(sm) {
      height: 0.6rem;
    }
    @include respond-above(lg) {
      height: 0.8rem;
    }
  }
}
