//creates gradient from base colour and optionally makes it transparent
@mixin gradientify(
  $base-color,
  $transparency: 0,
  $darken-by: 15%,
  $lighten-by: 15%,
  $dark-stop: 10%,
  $light-stop: 10%
) {
  //Fallback for browsers that don't support linear gradient.
  background: transparentize($base-color, $transparency);

  background: linear-gradient(
    to right bottom,
    darken(transparentize($base-color, $transparency), $darken-by) $dark-stop,
    transparentize($base-color, $transparency),
    100-$light-stop,
    lighten(transparentize($base-color, $transparency), $lighten-by)
  );
}
