@import "../../../SCSS/mixins/utilities";

.content_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  row-gap: 1.5rem;
  column-gap: 3rem;
  @include respond-above(md) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
}

.address {
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: start;
  font-size: 0.7rem !important;
  @include respond-above(sm) {
    grid-row: 1;
    grid-column: 1;
    font-size: 1.2rem !important;
  }
}
.signup {
  width: 100%;
  align-self: center;
  justify-self: center;
  grid-row: 2;
  grid-column: 1 / 3;
  // margin-top: 1.5rem;

  @include respond-above(md) {
    grid-row: 1;
    grid-column: 2;
    margin-bottom: 0;
  }
}
.socials {
  grid-row: 1;
  grid-column: 2;
  align-self: center;
  justify-self: end;
  @include respond-above(md) {
    grid-row: 1;
    grid-column: 3;
  }
}
