.spinner {
  width: 60% !important;
  max-width: 7rem !important;
  border-radius: 0.3rem;
  margin: auto;
  transform: translate(0);
  animation-name: candy_cane_spinner;
  animation-duration: 1.1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes candy_cane_spinner {
  0% {
    transform: scale(50%) rotate(0deg);
    opacity: 0%;
  }

  95% {
    transform: scale(100%) rotate(720deg);
    opacity: 100%;
  }
}
