//Colours
$sa-cream: rgb(255, 241, 212);
$sa-darker-cream: darken($sa-cream, 14%);
$sa-lighter-cream: lighten($sa-cream, 8%);
$sa-red: rgb(205, 0, 10);
$sa-black: rgb(25, 25, 25);

$custom-colors: (
  "sa-black": $sa-black,
  "sa-red": $sa-red,
  "sa-cream": $sa-cream,
  "sa-darker-cream": $sa-darker-cream,
);

//Typography
//?Base Font
//?Base Font Family for the entire site
$font-family-sans-serif: "Raleway", "Open Sans", -apple-system,
  BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji";
$font-family-base: $font-family-sans-serif;
//?Base font size (All other sizes are based off this
$font-size-base: 1.1rem; // Assumes the browser default, typically `16px`
//?Headings
$headings-font-family: "Mountains Of Christmas";
$h1-font-size: $font-size-base * 2.9;
//?lead paragraphs
$lead-font-size: $font-size-base * 1.4;
//Links
$link-color: $sa-cream;
$link-hover-color: $sa-darker-cream;

// Navbar
$navbar-padding-x: 0.5rem;
$navbar-toggler-padding-x: 0;

$navbar-dark-color: $sa-darker-cream;
$navbar-dark-hover-color: $sa-cream;
$navbar-dark-active-color: $sa-lighter-cream;
$navbar-dark-disabled-color: rgba(100, 100, 100, 30);
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$sa-darker-cream}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='1.2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color: $sa-black;
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-color;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
);

$custom-display-size: (
  13: 2.3rem,
  7: 2rem,
);

//$accordion-icon-width: 0;
$accordion-bg: $sa-cream;
$accordion-button-bg: $sa-cream;
$accordion-button-color: $sa-red;
$accordion-border-color: $sa-red;
$accordion-button-focus-border-color: $sa-red;
$accordion-button-focus-box-shadow: 0 0 0 0.25 $sa-red;
$accordion-border-width: 0.4rem;
$accordion-button-active-bg: $sa-red;
$accordion-button-active-color: $sa-cream;
$accordion-padding-y: 1rem;
$accordion-padding-x: 1rem;
$accordion-border-radius: 1.2rem;

// Progress bars
// scss-docs-start progress-variables
$progress-height: 1rem;
//$progress-font-size: $font-size-base * .75 !default;
$progress-bg: $sa-cream;
//$progress-border-radius: $border-radius !default;
// $progress-box-shadow: $box-shadow-inset !default;
//$progress-bar-color: hotpink;
$progress-bar-bg: hotpink;
// $progress-bar-animation-timing: 1s linear infinite !default;
// $progress-bar-transition: width .6s ease !default;
// scss-docs-end progress-variables

//! Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

$theme-colors: map-merge($theme-colors, $custom-colors);
$display-font-sizes: map-merge($display-font-sizes, $custom-display-size);

$utilities: map-merge(
  $utilities,
  (
    "width":
      map-merge(
        map-get($utilities, "width"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "width"), "values"),
              (
                30: 30%,
                40: 40%,
              )
            ),
          responsive: true,
        )
      ),
  )
);

//! OPTIONAL
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/carousel/";
@import "~bootstrap/scss/accordion/";
@import "~bootstrap/scss/helpers/position";
@import "~bootstrap/scss/helpers/ratio";
@import "~bootstrap/scss/utilities/api";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/progress";

.progress-bar {
  background: repeating-linear-gradient(
    -45deg,
    $sa-red,
    $sa-red 10px,
    white 10px,
    white 20px
  );
}
