.outer_wrapper {
  background: url("../../images/patterns/mainContainerPattern.gif");
  //background-size: 60%;
  display: flex;
  flex-flow: column;
}

.footer_row {
  height: 100px;
}
