@import "../../../../SCSS/mixins/utilities";

.socials_wrapper {
  max-width: 18rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.7rem;
  row-gap: 0.5rem;
  @include respond-above(sm) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.icon {
  font-size: 2.25rem;

  &:hover {
    transform: scale(1.2);
  }

  @include respond-above(md) {
    font-size: 2.5rem;
  }
  @include respond-above(lg) {
    font-size: 3rem;
  }
  @include respond-above(xl) {
    font-size: 4rem;
  }
}
