a {
  text-decoration: none;
}
.script {
  font-family: "Dancing Script";
}
.nonFancyHeading {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: $font-family-base;
  }
}
